export const infoJsonLinks = [
  "https://szmoon.github.io/tien-wei-chang-images/images/A01-N01/info.json",
  "https://szmoon.github.io/tien-wei-chang-images/images/A02-N01/info.json",
  "https://szmoon.github.io/tien-wei-chang-images/images/A03-N01/info.json",
  "https://szmoon.github.io/tien-wei-chang-images/images/A04-N01/info.json",
  "https://szmoon.github.io/tien-wei-chang-images/images/A05-N01/info.json",
  "https://szmoon.github.io/tien-wei-chang-images/images/A06-N01/info.json",
  "https://szmoon.github.io/tien-wei-chang-images/images/A07-N01/info.json",
  "https://szmoon.github.io/tien-wei-chang-images/images/B01-N01/info.json",
  "https://szmoon.github.io/tien-wei-chang-images/images/B07-N01/info.json",
  "https://szmoon.github.io/tien-wei-chang-images/images/B09-N01/info.json",
  "https://szmoon.github.io/tien-wei-chang-images/images/B11-N01/info.json",
];
