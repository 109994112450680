<template>
  <v-container>
    <h2 class="text-h4">A collection of works by Tien-Wei Chang</h2>

    <v-row class="mt-8">
      <v-col
        v-for="art in allArtwork"
        :key="art.id"
        class="d-flex child-flex"
        cols="6"
        sm="4"
      >
        <router-link :to="{ name: 'painting', params: { id: art.id } }">
          <v-img :src="art.thumbnail" contain max-height="266">
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
          <!-- <v-img
            :src="art.thumbnail"
            class="grey lighten-2"
            aspect-ratio="1.5"
            max-height="266"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img> -->
        </router-link>
      </v-col>
    </v-row>
    <div class="text-center mt-15">
      <v-pagination v-model="page" :length="6"></v-pagination>
    </div>
  </v-container>
</template>

<script>
import $ from "jquery";
import { infoJsonLinks } from "../../iiif-images";

export default {
  name: "Collection",
  data() {
    return { allArtwork: [] };
  },
  components: {},
  mounted() {
    infoJsonLinks.forEach((infoJson) => {
      $.getJSON(infoJson, (data) => {
        const artworkData = {
          id: this.getShortId(data),
          thumbnail: this.getThumbnail(data),
        };
        this.allArtwork.push(artworkData);
      });
    });
  },
  methods: {
    getShortId(infoJsonData) {
      const fullId = infoJsonData["@id"];
      const idParts = fullId.split("/");
      return idParts[idParts.length - 1];
    },
    getThumbnail(infoJsonData) {
      const width = infoJsonData.sizes[2].width;
      const fullId = infoJsonData["@id"];

      const thumbnailUrl = fullId + "/full/" + width + ",/0/default.jpg";

      return thumbnailUrl;
    },
  },
};
</script>

<style lang="scss"></style>
